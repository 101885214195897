import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";

const SpecialOffers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Special Offers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
      <Product
              _id= "1008"
              img= "https://static-01.daraz.pk/p/c5c85f39df2667f46f7731c316c0d510.jpg_750x750.jpg_.webp"
              cat= "electronics"
              productName= "(Deal Pack Of 2 ) M10 & S8 Ultra Smart Watch"
              price= "3099"
              color= "mix"
              badge= "true"
              sold= "Offer"
              des= ""         />
        <Product
            _id= "4024"
            img= "https://hhcdropshipping.com/Member/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-26-at-11.17.40-PM-2.jpeg"
            cat= "lifestyle"
            productName= "Black 2pc Suit Plain Shirt & Trouser"
            price= "1999.00"
            color= "Mixed"
            badge= {true}
            sold= "Offer"
            des= ""
            />
        <Product
            _id= "1006"
            img= "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRvvedUzqJIpAYXICj8XXzkQdjPEOaQwJKmA&usqp=CAU"
            cat= "electronics"
            productName= "Juice Cup "
            price= "1899"
            color= "mix"
            badge= "true"
            sold= "Offer"
            des= ""
            />
        <Product
           _id= "3007"
           img= "https://hhcdropshipping.com/Member/wp-content/uploads/2024/02/3129db65-2062-4f0b-b4da-2c50a4a2f70e.jpeg"
           cat= "stationary"
           productName= "Diy Assembly Physics Electric Circuit Toy (for childs)"
           price= "899.00"
           color= "Blank and White"
           badge= {true}
           sold= "Offer"
           des= ""
        />
      </div>
    </div>
  );
};

export default SpecialOffers;
