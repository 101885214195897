import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="New Arrivals" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="4011"
            img="https://hhcdropshipping.com/Member/wp-content/uploads/2022/01/24-K-GOLDEN-900x900.jpg"
            cat="lifestyle"
            productName="Original 24k Gold Serum"
            price="999.00"
            color="Gold"
            badge="true"
            sold="New"
            des=""
          />
        </div>
        <div className="px-2">
          <Product
            _id="4026"
            img="https://hhcdropshipping.com/Member/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-05-at-3.50.57-PM.jpeg"
            cat="lifestyle"
            productName="Stitched 3pc Full Print Kataan Silk Maxi"
            price="3599.00"
            color="Mixed"
            badge={true}
            sold="New"
            des=""
          />
        </div>
        <div className="px-2">
          <Product
            _id="1009"
            img="https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/H5f17bdd95e8d4dfe949f397de1aa7cecy-1.jpg"
            cat="electronics"
            productName="T9 Hair Trimmer"
            price="1149.00"
            color="Blank and Yellow"
            badge="true"
            sold="New"
            des="" />
        </div>
        <div className="px-2">
          <Product

            _id="4025"
            img="https://hhcdropshipping.com/Member/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-26-at-12.32.44-PM-scaled.jpeg"
            cat="lifestyle"
            productName="Fitross Watch For Men"
            price="1669.00"
            color="Blank and White"
            badge={true}
            sold="New"
            des="Say goodbye to stubborn clogs with our powerful Drain Clog Remover, the ultimate solution for keeping your pipes clear and water flowing smoothly"
          />
        </div>
        <div className="px-2">
          <Product
            _id="4000"
            img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU0mhyApAS98bmi57dyEEFvFhmbyfgRWgsSEDMa0L6le0tohaGwQPCVlU&s=10"
            cat="lifestyle"
            productName="BNB Whitening Rice kit"
            price="1599.00"
            color="White"
            badge={true}
            sold="New"
            des="" />
        </div>
      </Slider>
    </div>
  );
};

export default NewArrivals;
