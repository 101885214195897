import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import ProductsOnSale from "../../components/pageProps/productDetails/ProductsOnSale";
import { paginationItems } from "../../constants";

const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation] = useState("");
 
  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
   <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
        <div className="h-full">
            <ProductsOnSale />
          </div>
       <>
      {paginationItems.map(item => {
         const formattedProductName = item.productName.replace(/\s/g, '').toLowerCase();
        const currentRoute = location.pathname.split('/').pop(); // Get the current route
        console.log(formattedProductName,currentRoute)
        if (formattedProductName === currentRoute) {

          return (
           <>
             <div className="h-full xl:col-span-2">
           <img
              className="w-full h-full object-cover"
              src={item.img}
              alt={item.img}
            />
          </div>
           <div className="h-full w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
            <ProductInfo productInfo={item} />
          </div>
           </>
          );
        } else {
          return null; // If the product name doesn't match the current route, return null
        }
      })}
    </>


        
        
         
         
          
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;































// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
// import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
// import ProductsOnSale from "../../components/pageProps/productDetails/ProductsOnSale";

// const ProductDetails = () => {
//   const location = useLocation();
//   const [prevLocation, setPrevLocation] = useState("");
//   const [productInfo, setProductInfo] = useState([]);

//   useEffect(() => {
//     setProductInfo(location.state.item);
//     setPrevLocation(location.pathname);
//   }, [location, productInfo]);

//   return (
//     <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
//       <div className="max-w-container mx-auto px-4">
//         <div className="xl:-mt-10 -mt-7">
//           <Breadcrumbs title="" prevLocation={prevLocation} />
//         </div>
//         <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
//           <div className="h-full">
//             <ProductsOnSale />
//           </div>
//           <div className="h-full xl:col-span-2">
//             <img
//               className="w-full h-full object-cover"
//               src={productInfo.img}
//               alt={productInfo.img}
//             />
//           </div>
//           <div className="h-full w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
//             <ProductInfo productInfo={productInfo} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductDetails;

