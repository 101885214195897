import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWhatsAppClick = () => {
      const phoneNumber = '923099238800'; // Replace with the phone number
      
      // You can modify the message format as per your requirement
      const message = 'I want to buy this: ' + window.location.href;
  
      const whatsappURL = 'https://api.whatsapp.com/send?phone=' + phoneNumber + '&text=' + encodeURIComponent(message);
      
      window.location.href = whatsappURL;
    };

    const whatsappLink = document.getElementById('whatsapp-link');
    if (whatsappLink) {
      whatsappLink.addEventListener('click', handleWhatsAppClick);
    }

    // Clean up event listener on component unmount
    return () => {
      if (whatsappLink) {
        whatsappLink.removeEventListener('click', handleWhatsAppClick);
      }
    };
  }, []);

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">PKR {productInfo.price}</p>
      <p className="text-base text-gray-600">{productInfo.des}</p>
      <p className="text-sm">100% Money Back Gurrantee</p>
      <p className="font-medium text-lg">
        <span className="font-normal">Colors:</span> {productInfo.color}
      </p>
      <button
        onClick={() =>
          dispatch(
            addToCart({
              _id: productInfo.id,
              name: productInfo.productName,
              quantity: 1,
              image: productInfo.img,
              badge: productInfo.badge,
              price: productInfo.price,
              colors: productInfo.color,
            })
          )
        }
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
        id="whatsapp-link" >
        Add to Cart
      </button>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span> Spring
        collection, Streetwear, Women Tags: featured SKU: N/A
      </p>
    </div>
  );
};

export default ProductInfo;
