import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  bestSellerFour,
} from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
           _id= "2005"
           img= "https://static-01.daraz.pk/p/9713821abd674d0538aa6f2e6cefba72.jpg_750x750.jpg_.webp"
           cat= "kitchenware"
           productName= "Electric Stove"
           price= "2450.00"
           color= "White"
           badge= {true}
           sold= "Best"
           des= ""   />

        <Product
          _id= "1008"
          img= "https://static-01.daraz.pk/p/c5c85f39df2667f46f7731c316c0d510.jpg_750x750.jpg_.webp"
          cat= "electronics"
          productName= "(Deal Pack Of 2 ) M10 & S8 Ultra Smart Watch"
          price= "3099"
          color= "mix"
          badge= "true"
          sold= "Best"
          des= ""       />

        <Product
          _id= "4007"
          img= "https://babyboutique.pk/cdn/shop/products/71p2MoyvMLS._AC_SL1500.jpg?v=1656098972"
          cat= "lifestyle"
          productName= "Dancing Cactus Toy with Recording"
          price= "1999.00"
          color= "Mixed"
          badge= {true}
          sold= "Best"
          des= ""
      
         />
        <Product
          _id="1014"
          img={bestSellerFour}
          cat= "bags"
          productName="Travel Bag"
          price="220.00"
          color="Black"
          badge={true}
          sold= "Best"
          des=""
        />
      </div>
    </div>
  );
};

export default BestSellers;
